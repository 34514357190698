import React from 'react'
import {
  Container,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col
} from 'reactstrap'
import { HTMLContent } from '../components/Content'
import bannerImage from '../img/contact.jpg'
import Banner from '../components/Banner'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

const cardsData = {
  neverEvents: {
    title: 'Eliminate Never Events',
    slug: `SafeStart is designed to eliminate Never Events through
    accurate and vetted information to eliminate wrong
    patient, wrong procedure, and wrong site operations.
    <ul>
      <li>Reduction of patient payouts for Never Events</li>
      <li>
        Reduction in internal investigation time for Never
        Events
      </li>
      <li>
        Reduction in malpractice premiums due to Never Events
      </li>
      <li>
        Patient participation in liability sharing by signing
        off on surgical checklists
      </li>
    </ul>`
  },
  throughput: {
    title: 'Operating Room Throughput',
    slug: `SafeStart provides enhanced patient safety data and
    information that prevents delays and cancellations in the
    Operating Room allowing better Operating Room schedule
    continuity, throughput, and efficiency.
    <ul>
      <li>Elimination of delays</li>
      <li>Elimination of cancellations</li>
      <li>Increased capacity of the Operating Rooms</li>
      <li>
        Improvement of Operating Room schedule continuity
      </li>
      <li>
        Reduction in overtime expense due to reduction of
        Operating Room delays
      </li>
    </ul>
    `
  },
  staffSatisfaction: {
    title: 'Staff Satisfaction',
    slug: `SafeStart eliminates delays in the Operating Room thus
      reducing the amount of wasted waiting time for surgeons
      and nurses in the Operating Room. Also, reducing the
      amount of Never Events will improve staff morale.
      <ul>
        <li>Improved staff satisfaction scores</li>
        <li>
          Help reduce staff turnover reducing recruiting expense
        </li>
        <li>
          Help recruit clinicians through the enhanced safety
          leadership position
        </li>
      </ul>`
  },
  engagement: {
    title: 'Patient Engagement',
    slug: `SafeStart includes the patients and their families in the
      safety process through outreach to allow the patients to
      review their safety information and become part of the
      safety process.
      <ul>
        <li>
          Include surgery patients and their families in the
          hospital safety process
        </li>
        <li>Enhance the hospital brand of safety</li>
        <li>Increase patient satisfaction scores</li>
      </ul>`
  },
  meaningfulUse: {
    title: 'Meaningful Use',
    slug: `SafeStart can drive EMR patient portal usage by delivering critical patient safety information that patients will actively seek helping providers achieve patient portal meaningful use metrics.
    <ul>
      <li>
        Leverage hospital investment in multi-million dollar
        EMR investments
      </li>
      <li>Drive patient portal registrations</li>
      <li>Drive ongoing patient portal usage</li>
      <li>
        Help drive achievement of Meaningful Use for patient
        portal usage
      </li>
    </ul>`
  },
  safetyCulture: {
    title: 'Culture of Safety',
    slug: `SafeStart with its introduction across the provider
    workflow of the office, the patient experience, and the
    OR, will create a top-of-mind focus on safety enhancing a
    hospital's brand as safety conscious.
    <ul>
      <li>
        Embed and strengthen a focus on safety by clinical and
        office personnel
      </li>
      <li>
        Extend Culture of Safety to external hospital
        communications
      </li>
      <li>
        Leverage Culture of Safety to external marketing and
        branding
      </li>
    </ul>`
  },
  cahps: {
    title: 'OAS CAHPS',
    slug: `SafeStart can drive OAS CAHPS scores. CMS has moved to mandatory patient satisfaction surveys. SafeStart provides patients with safety information and participation in the surgical safety process that will improve OAS CAHPS scores.
    <ul>
      <li>
        Leverage hospital or facility investment in EMRs
      </li>
      <li>
        Drive patient portal registrations
      </li>
      <li>
        Drive ongoing patient portal usage
      </li>
      <li>
        Help drive achievement of OAS CAHPS goals
      </li>
    </ul>
`
  },
  hcahps: {
    title: 'HCAHPS',
    slug: `SafeStart can drive HCAHP scores. CMS has moved to mandatory patient satisfaction surveys. SafeStart provides patients with safety information and participation in the surgical safety process that will improve HCAHPS scores.
    <ul>
      <li>
        Leverage hospital or facility investment in EMRs
      </li>
      <li>
        Drive patient portal registrations
      </li>
      <li>
        Drive ongoing patient portal usage
      </li>
      <li>
        Help drive achievement of HCAHPS goals
      </li>
    </ul>
`
  }
}

export const WhoWeServeTemplate = ({ title, content, cards }) => {
  const pageClass = title.replace(' ', '-')

  let cardsArray = []
  cards.forEach(card => {
    if (cardsData.hasOwnProperty(card.key)) {
      cardsArray.push(cardsData[card.key])
    }
  })

  const renderedCards = cardsArray.map((card, key) => (
    <Col xs="12" sm="6" key={key} className="space-below">
      <Card>
        <CardHeader className="bg-primary">
          <CardTitle style={{ marginBottom: 0 }}>{card.title}</CardTitle>
        </CardHeader>
        <CardBody>
          <div dangerouslySetInnerHTML={{ __html: card.slug }} />
        </CardBody>
      </Card>
    </Col>
  ))

  return (
    <Layout>
      <div className={pageClass ? 'who-we-serve ' + pageClass : 'who-we-serve'}>
        <Banner image={bannerImage} title={title} />
        <section className="padded-section-half">
          <Container>
            <h3>
              EMR — No Problem! We can integrate with any EMR
              <br />
              No EMR — No Problem! SafeStart works with or without an EMR
            </h3>
          </Container>
        </section>
        <section className="padded-section bg-light">
          <Container>
            {/*
            <h2 className="space-below">
              SafeStart eliminates Never Events, enhances patient engagement and
              satisfaction as well as increases operating room throughput and
              utilization.
            </h2>
            */}
            <Row>{renderedCards}</Row>
          </Container>
        </section>

        {content && (
          <section className="padded-section">
            <Container>
              <HTMLContent content={content} />
            </Container>
          </section>
        )}
      </div>
    </Layout>
  )
}

export default ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <WhoWeServeTemplate
      title={post.frontmatter.title}
      content={post.html}
      cards={post.frontmatter.cards}
    />
  )
}

export const pageQuery = graphql`
  query WhoWeServe($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        cards {
          key
        }
      }
    }
  }
`
